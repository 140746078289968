import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {Router} from 'express'
import Widget from './Widget'
import RetoolApp from './RetoolApp';
import { OppUUIDComponentProps } from './Interfaces';
// import './RetoolWorker';


// window.addEventListener('message', function(event) {
//   console.log('')
//   if (event.origin !== 'https://getsales.lightning.force.com') {
//       return; // Ignore messages from unknown origins
//   }
  
//   const data = event.data;
//   console.log(`Account ID: ${data.accountId}, Account Name: ${data.accountName}`);
//   // Process the data as needed
// });

function App() {
  const [oppData, setOppData] = useState<OppUUIDComponentProps>({opportunity_uuid: '', phone_number: '', agent: ''})


  useEffect(() => {
    console.log('Adding message event listener');

    function handleMessage(event: any) {
      console.log('Message event received:', event);
      console.log('Event origin:', event.origin);

      if (event.origin !== 'https://getsales--c.vf.force.com') {
        console.log('Origin mismatch: expected https://getsales--c.vf.force.com but received', event.origin);
        return; // Ignore messages from unknown origins
      }

      const data = event.data;
      console.log(`The DATA: ${data}`);

      // session_id: '',
      //           opportunity_uuid: '{!lead.Opportunity_UUID__c}',
      //           partner: '{!lead.Partner__c}',
      //           opportunity_type: '{!lead.Opportunity_Type__c}',
      //           bgc_passed_date: '{!lead.Criminal_BGC_Passed_Date_Time__c}',
      //           activation_date: '{!lead.Activation_Date__c}',
      //           first_delivery_date: '{!lead.First_Delivery_Date__c}',
      //           last_delivery_date: ''
      // Process the data as needed
      setOppData(data)
    }

    window.addEventListener('message', handleMessage);

    return () => {
      console.log('Removing message event listener');
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      <Widget {...oppData} />
      {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      {/* </header> */}
    </div>
  );
}

export default App;
